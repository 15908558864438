import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Announcement } from '@models/announcement';
import {
  addDays,
  addYears,
  endOfDay,
  endOfHour,
  format,
  parse,
  startOfDay,
  startOfHour,
} from 'date-fns';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';
import { MAT_DATE_LOCALE } from '@angular/material/core';
export interface FormValues {
  durationInSecs: number;
  publishNow: boolean;
  stopShowing: string;
  startShowing: string;
  startShowingTime: string;
  stopShowingTime: string;
}
@Component({
  selector: 'app-slide-preferences',
  templateUrl: './slide-preferences.component.html',
  styleUrls: ['./slide-preferences.component.scss'],
  providers: [
    // {
    //   provide: DateAdapter,
    //   useClass: CustomDateAdapter,
    // },
    provideDateFnsAdapter({
      parse: {
        dateInput: ['yyyy-MM-dd', 'MM/dd/yyyy'],
      },
      display: {
        dateInput: 'yyyy-MM-dd',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
      },
    }),
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
  ],
})
export class SlidePreferencesComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  public publishForever = false;
  public publishImmediately = true;
  public today = new Date();
  private defaultStopShowingDateTime = endOfDay(addDays(new Date(), 14));
  private permanentStopShowingDateTime = endOfDay(addYears(new Date(), 50));

  private defaultStartShowingDateTime = startOfDay(new Date());

  static serialize(form: FormValues): Partial<Announcement> {
    // tslint:disable-next-line:max-line-length
    let startShowing: string | Date = form.startShowing;
    if ((startShowing as any) instanceof Date) {
      startShowing = format(new Date(startShowing), 'yyyy-MM-dd');
    }
    const startShowingDateTime = form.publishNow
      ? startOfDay(new Date())
      : parse(
          `${startShowing} ${form.startShowingTime}`,
          'yyyy-MM-dd HH:mm',
          new Date(),
        );

    // date pickers can be so finnicky. Making sure that we have the right format for the date
    let stopShowing: string | Date = form.stopShowing;
    if ((stopShowing as any) instanceof Date) {
      stopShowing = format(new Date(stopShowing), 'yyyy-MM-dd');
    }

    const stopShowingDateTime = parse(
      `${stopShowing} ${form.stopShowingTime}`,
      'yyyy-MM-dd HH:mm',
      new Date(),
    );

    return {
      displayDuration: form.durationInSecs,
      startShowing: startShowingDateTime,
      stopShowing: stopShowingDateTime,
    };
  }

  static deserializeToForm(data: any): FormValues {
    return {
      durationInSecs: data.displayDuration,
      publishNow: false, // editing
      startShowing: format(new Date(data.startShowing), 'yyyy-MM-dd'),
      stopShowing: format(new Date(data.stopShowing), 'yyyy-MM-dd'),
      startShowingTime: format(new Date(data.startShowing), 'HH:mm'),
      stopShowingTime: format(new Date(data.stopShowing), 'HH:mm'),
    };
  }

  static formModel() {
    return {
      durationInSecs: new UntypedFormControl(15, Validators.required),
      publishNow: new UntypedFormControl(true),
      startShowing: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd')),
      stopShowing: new UntypedFormControl(
        format(addDays(new Date(), 14), 'yyyy-MM-dd'),
        Validators.required,
      ),
      startShowingTime: new UntypedFormControl(
        format(startOfHour(new Date()), 'HH:mm'),
      ),
      stopShowingTime: new UntypedFormControl(
        format(endOfHour(new Date()), 'HH:mm'),
        Validators.required,
      ),
    };
  }

  constructor() {}

  ngOnInit() {
    // Set initial state based on form values
    this.publishImmediately = this.form.get('publishNow').value;

    // Set initial validators based on publishImmediately
    if (this.publishImmediately) {
      this.form.get('startShowing').clearValidators();
      this.form.get('startShowingTime').clearValidators();
    } else {
      this.form.get('startShowing').setValidators(Validators.required);
      this.form.get('startShowingTime').setValidators(Validators.required);
    }

    // Check for permanent announcement
    if (this.form?.controls?.stopShowing?.value) {
      const stopShowingDateTime = this.form.controls.stopShowing.value;
      const permanentTarget = addYears(new Date(), 30);

      if (stopShowingDateTime > permanentTarget) {
        this.publishForever = true;
        this.form.get('stopShowing').clearValidators();
        this.form.get('stopShowingTime').clearValidators();
      }
    }

    // Update validity for all fields
    [
      'startShowing',
      'startShowingTime',
      'stopShowing',
      'stopShowingTime',
    ].forEach((field) => {
      this.form
        .get(field)
        .updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });

    // Trigger change detection
    setTimeout(() => {
      this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });
  }

  togglePublishImmediately() {
    this.publishImmediately = !this.publishImmediately;
    if (this.publishImmediately) {
      const now = new Date();
      this.form.patchValue({
        startShowing: format(now, 'yyyy-MM-dd'),
        startShowingTime: format(now, 'HH:mm'),
      });

      // Clear validators for start fields
      this.form.get('startShowing').clearValidators();
      this.form.get('startShowingTime').clearValidators();

      // Ensure stop showing fields have required validators unless publishForever is true
      if (!this.publishForever) {
        this.form.get('stopShowing').setValidators(Validators.required);
        this.form.get('stopShowingTime').setValidators(Validators.required);
      }
    } else {
      this.form.patchValue({
        startShowing: format(this.defaultStartShowingDateTime, 'yyyy-MM-dd'),
        startShowingTime: format(this.defaultStartShowingDateTime, 'HH:mm'),
      });

      // Restore required validators for all fields
      this.form.get('startShowing').setValidators(Validators.required);
      this.form.get('startShowingTime').setValidators(Validators.required);
      if (!this.publishForever) {
        this.form.get('stopShowing').setValidators(Validators.required);
        this.form.get('stopShowingTime').setValidators(Validators.required);
      }
    }

    // Update validity for all fields
    this.form.get('startShowing').updateValueAndValidity();
    this.form.get('startShowingTime').updateValueAndValidity();
    this.form.get('stopShowing').updateValueAndValidity();
    this.form.get('stopShowingTime').updateValueAndValidity();
  }

  togglePublishForever() {
    this.publishForever = !this.publishForever;
    if (this.publishForever) {
      this.form.patchValue({
        stopShowing: format(this.permanentStopShowingDateTime, 'yyyy-MM-dd'),
        stopShowingTime: format(this.permanentStopShowingDateTime, 'HH:mm'),
      });
      // Remove validators for stop showing fields
      this.form.get('stopShowing').clearValidators();
      this.form.get('stopShowingTime').clearValidators();
    } else {
      this.form.patchValue({
        stopShowing: format(this.defaultStopShowingDateTime, 'yyyy-MM-dd'),
        stopShowingTime: format(this.defaultStopShowingDateTime, 'HH:mm'),
      });
      // Restore required validators
      this.form.get('stopShowing').setValidators(Validators.required);
      this.form.get('stopShowingTime').setValidators(Validators.required);
    }

    // Update validity
    this.form.get('stopShowing').updateValueAndValidity();
    this.form.get('stopShowingTime').updateValueAndValidity();
  }
}
